import { Box } from '@mui/material';
import React from 'react';

const Flexbox = ({ children, width = '100%', ...props }) => {
  return (
    <Box display="flex" {...props} width={width}>
      {children}
    </Box>
  );
};

export default Flexbox;
