import axios from 'axios';

export const API_URL = 'https://book.anywhereclinic.com/api/v1';

const defaultInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

defaultInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

defaultInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  },
);

const apiRequest = ({
  url,
  method = 'GET',
  data,
  params,
  headers,
  responseType,
}) => {
  return defaultInstance({
    url,
    method,
    data,
    params,
    headers,
    responseType,
  })
    .then((response) => {
      const body = response.data;
      return body;
    })
    .catch((e) => {
      return e.response;
    });
};

export default apiRequest;
