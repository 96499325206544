import { Button, TextField, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';

export const StyledWrapper = styled('div')(() => ({
  maxWidth: '500px',
  width: '100%',
  backgroundColor: '#fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px 10px',
  borderRadius: '10px',
  outline: 'none',

  '@media(max-width: 840px)': {
    maxWidth: '95%',
    h2: {
      fontSize: '20px',
    },
  },
}));

export const Heading = styled('h2')(() => ({
  fontSize: '24px',
  fontWeight: '500',
  padding: '0 15px',
  textAlign: 'left',
  margin: 0,
}));

export const LoadingButtonStyled = styled(LoadingButton)(() => ({
  fontSize: '14px',
  width: '100px',
  padding: '10px',
  textTransform: 'unset',
  fontWeight: '500',
  textAlign: 'left',
  margin: 0,
  boxShadow: 'none !important',
  borderRadius: '100px',
  span: {
    color: '#fff',
  },
}));

export const ButtonBase = styled(Button)(() => ({
  fontSize: '14px',
  width: '100px',
  padding: '10px',
  textTransform: 'unset',
  fontWeight: '500',
  textAlign: 'left',
  margin: 0,
  boxShadow: 'none !important',
  borderRadius: '100px',
}));

export const TextFieldBase = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root.Mui-error': {
    border: '1px solid #f81d22 !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#f81d22 !important',
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'Open Sans',
    left: 8,
    color: 'rgba(49, 64, 92, 1) !important',
    fontWeight: 600,
    fontSize: '14px',
    top: '-1px',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(12px, 8px) scale(0.75)',
  },
  input: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    padding: '20px 6px 8px',
  },
  '& .MuiInputBase-root': {
    fontFamily: 'Open Sans',
    color: 'rgba(49, 64, 92, 1)',
    backgroundColor: 'transparent',
    border: '1px solid rgba(49, 64, 92, 0.3)',
    borderRadius: '100px',
    paddingLeft: '15px !important',
    paddingRight: '10px !important',
    fontSize: '14px',
    '&::after': {
      display: 'none !important',
    },
    '&::before': {
      display: 'none !important',
    },
    svg: {
      color: 'rgba(49, 64, 92, 1)',
    },
  },
}));

export const StyledPicker = styled(DatePicker)(() => ({
  width: '100%',
  '& .MuiInputBase-root.Mui-error': {
    border: '1px solid #f81d22 !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#f81d22 !important',
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'Open Sans',
    left: 8,
    color: 'rgba(49, 64, 92, 1) !important',
    fontWeight: 600,
    fontSize: '14px',
    top: '-1px',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(12px, 8px) scale(0.75)',
  },
  input: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    padding: '20px 6px 8px',
  },
  '& .MuiInputBase-root': {
    fontFamily: 'Open Sans',
    color: 'rgba(49, 64, 92, 1)',
    backgroundColor: 'transparent',
    border: '1px solid rgba(49, 64, 92, 0.3)',
    borderRadius: '100px',
    paddingLeft: '15px !important',
    paddingRight: '10px !important',
    fontSize: '14px',
    '&::after': {
      display: 'none !important',
    },
    '&::before': {
      display: 'none !important',
    },
    svg: {
      color: 'rgba(49, 64, 92, 1)',
    },
  },
}));
