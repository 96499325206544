/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/iframe-has-title */
import { Modal, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledWrapper } from './PaymentModule.styled';
import DICTIONARY from '../../constants/Dictionary';
import { createAppoinment } from '../../api/requests';
import toast from 'react-hot-toast';
import { ScreenLoader } from '../../components';
import { API_URL } from '../../api/api';

const PaymentModule = ({
  open,
  onClose,
  location,
  apppointment,
  data,
  _apiUpdate,
}) => {
  const mobile = useMediaQuery('(max-width: 768px)');
  const appointmentLocale = localStorage.getItem(DICTIONARY.APPOINTMENT);
  const iframeRef = React.createRef();
  const [token, setToken] = useState(null);
  const [transId, setTransId] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (open) {
      const formData = new FormData();

      const currentPatientPrice =
        data?.provider?.current_patient_appointment_cost;
      const newPatientPrice = data?.provider?.new_patient_appointment_cost;
      const amount =
        apppointment?.patient_status === 'new'
          ? newPatientPrice
          : currentPatientPrice;

      const providerName =
        data?.provider?.first_name + ' ' + data?.provider?.last_name;
      const patientName =
        apppointment?.patient_first_name +
        ' ' +
        apppointment?.patient_last_name;

      formData.append(
        'payment_data',
        JSON.stringify({
          provider_name: providerName,
          amount,
          patient_name: patientName,
          patient_type: apppointment?.patient_status,
          location: location?.name,
        }),
      );
      fetch(`${API_URL}/payment-token/`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setToken(data?.token);
        });
    }
  }, [open]);

  useEffect(() => {
    const submitButton = () => {
      const btn = document.getElementById('submit-autorize');
      if (btn) {
        btn.click();
      }
    };

    submitButton();
  }, [token, open]);

  useEffect(() => {
    // Fetch the token from your server or Authorize.net API
    // Simulating token fetch
    // Setting up the communicator

    window.AuthorizeNetIFrame = {
      onReceiveCommunication: (querystr) => {
        const params = new URLSearchParams(querystr);
        const action = params.get('action');
        switch (action) {
          case 'successfulSave':
            alert('Payment saved successfully!');
            break;
          case 'cancel':
            onClose();
            break;
          case 'transactResponse':
            const response = params.get('response');
            const json = JSON.parse(response);
            const transactionId = json?.transId;
            setTransId(transactionId);
            onClose();
            break;
          case 'resizeWindow':
            const width = params.get('width');
            const height = params.get('height');
            if (iframeRef.current) {
              iframeRef.current.style.width = `${width}px`;
              iframeRef.current.style.height = `${height}px`;
            }
            break;
          default:
            break;
        }
      },
    };
  }, []);

  useEffect(() => {
    if (transId) {
      setLoader(true);
      const jsonAppointment = JSON.parse(appointmentLocale);
      const payload = { ...jsonAppointment, transaction_id: transId };

      createAppoinment(payload)
        .then((response) => {
          if (response.status && response.status === 400) {
            setLoader(false);
            toast.error(response?.data?.detail);
          } else {
            toast.success('Your appointment created successfully!', {
              style: {
                minWidth: '400px',
              },
            });
            _apiUpdate(true);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [transId]);

  // console.log(appointmentLocale, transId);

  return (
    <>
      {loader && <ScreenLoader />}
      <Modal open={open} onClose={onClose}>
        <StyledWrapper>
          <form
            method="post"
            action="https://accept.authorize.net/payment/payment"
            target="paymentIframe"
          >
            <input type="hidden" name="token" value={token} />
            <button type="submit" id="submit-autorize" style={{ opacity: 0 }}>
              Open Payment Form
            </button>
          </form>
          {!mobile && (
            <iframe
              ref={iframeRef}
              id="paymentIframe"
              name="paymentIframe"
              src="about:blank"
              width="100%"
              height="1000px"
              frameBorder="0"
              scrolling="no"
            />
          )}
          {mobile && (
            <iframe
              ref={iframeRef}
              id="paymentIframe"
              name="paymentIframe"
              src="about:blank"
              width="100%"
              height="1300px"
              frameBorder="0"
              scrolling="no"
            />
          )}
        </StyledWrapper>
      </Modal>
    </>
  );
};

export default PaymentModule;
