export const HEARD_ABOUT_LIST = [
  {
    label: 'Google',
    name: 'Google',
  },
  {
    label: 'Facebook',
    name: 'Facebook',
  },
  {
    label: 'Instagram',
    name: 'Instagram',
  },
  {
    label: 'Referral',
    name: 'Referral',
  },
  {
    label: 'Reddit',
    name: 'Reddit',
  },
  {
    label: 'Other',
    name: 'Other',
  },
];

const DICTIONARY = {
  OTHER_HEARD_ABOUT: 'Other',
  APPOINTMENT: 'appointment',
};

export default DICTIONARY;
