import { Pagination, styled } from '@mui/material';

const RootPagination = styled(Pagination)(() => ({
  '& .Mui-selected': {
    transition: 'all .3s',
    backgroundColor: '#31405c',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#31405c',
      color: '#fff',
      opacity: '0.5',
    },
  },

  button: {
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: '#31405c',
      color: '#fff',
      opacity: '0.5',
    },
  },
}));

export default RootPagination;
