import { styled } from '@mui/material';
import React from 'react';

const Wrapper = styled('div')(() => ({
  height: '60vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',

  '@media(max-width: 768px)': {
    height: '20vh',
  },
}));

const Text = styled('p')(() => ({
  fontSize: 20,
  margin: 0,
  maxWidth: 600,
  lineHeight: 1.4,

  '@media(max-width: 768px)': {
    fontSize: 14,
  },
}));

const EmptyBox = () => {
  return (
    <Wrapper>
      <Text>Please use the filters above to narrow down your search.</Text>
      <Text>
        Select your location, the type of therapy you are looking for, and your
        insurance plan to see available providers.
      </Text>
      <Text>
        If no results are found, try adjusting the filters for more options.
      </Text>
    </Wrapper>
  );
};

export default EmptyBox;
