import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SimpleBar from 'simplebar-react';
import React, { useState } from 'react';
import { Modal } from '@mui/material';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { BaseSelect, Flexbox, ScreenLoader } from '../../components';
import { createAppoinment } from '../../api/requests';
import {
  ButtonBase,
  Heading,
  LoadingButtonStyled,
  StyledPicker,
  StyledWrapper,
  TextFieldBase,
} from './ApointmentModule.styled';
import DICTIONARY, { HEARD_ABOUT_LIST } from '../../constants/Dictionary';
import useForm from '../../hooks/useForm';

const generateTime = (data) => {
  let timeSlotnew = null;
  if (data?.time) {
    const currentDate = data?.time?.data?.CalendarDate;
    const slot = data?.time?.item;
    const ampm2military = (ampm) =>
      ampm ? dayjs(`1/1/1 ${ampm}`).format('HH:mm') : null;
    const dayjsTime = dayjs(currentDate).format('YYYY-MM-DD');
    timeSlotnew = `${dayjsTime}T${ampm2military(slot)}`;
  }
  return timeSlotnew;
};
const AppointmentModule = ({
  patientStatus,
  insuranceCarrier,
  insurancePlan,
  selfPay,
  stateName,
  visitReasons,
  open,
  onClose,
  data,
  _apiUpdate,
  setAppointmentFormData,
}) => {
  const [loader, setLoader] = useState(false);
  const [insurancesValue, setInsurancesValue] = useState(null);
  const [gender, setGender] = useState(null);
  const [heard, setHeard] = useState(null);
  const [errors, setErrors] = useState({});
  const [isOtherAboutUs, setIsOtherAboutUs] = useState(false);
  const [heardAboutUsOther, setHeardAboutUsOther] = useState(null);
  const [user, setUser] = useState({
    patient_phone_number: null,
    patient_first_name: null,
    patient_last_name: null,
    patient_email: null,
  });
  const {
    requireValidate,
    errors: formErrors,
    setFields,
    setErrors: setFormErrors,
  } = useForm();
  const [date, setDate] = useState(null);

  const bindClose = () => {
    onClose();
    setUser({
      patient_phone_number: '',
      patient_first_name: '',
      patient_last_name: '',
      patient_email: '',
    });
    setDate(null);
    setGender(null);
    setHeard(null);
    setInsurancesValue(null);
    setErrors({});
    setHeardAboutUsOther(null);
    setIsOtherAboutUs(false);
    setFields({});
    setFormErrors({});
  };

  const handleSubmit = () => {
    let number = user?.patient_phone_number;
    setLoader(true);
    const payload = {
      ...user,
      patient_phone_number: number,
      patient_date_of_birth: date && date.format('YYYY-MM-DD'),
      patient_clinical_gender: gender?.name,
      patient_status:
        patientStatus?.name === 'Current Patient' ? 'current' : 'new',
      heard_about_us: heard?.name,
      provider: data?.provider?.id,
      visit_reason: visitReasons?.id,
      insurance: insurancesValue?.id,
      state: stateName?.id,
      heard_about_us_other: heardAboutUsOther,
      time_slot: generateTime(data),
      insurance_carrier: insuranceCarrier?.id,
      insurance_plan: insurancePlan?.id,
      self_pay: selfPay,
    };
    createAppoinment(payload)
      .then((response) => {
        if (response.status && response.status === 500) {
          setLoader(false);
          toast.error('Oops... Something went wrong!', {
            style: {
              minWidth: '400px',
            },
          });
        } else if (response.status && response.status === 400) {
          const errors = response.data;
          if (errors.error) {
            setErrors({});
            toast.error(errors.error, {
              style: {
                minWidth: '400px',
              },
            });
          } else if (errors.detail) {
            toast.error(errors.detail);
          } else setErrors(errors);
          setLoader(false);
        } else {
          setLoader(false);
          bindClose();
          _apiUpdate(true);
          setErrors({});
          toast.success('Your appointment created successfully!', {
            style: {
              minWidth: '400px',
            },
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  const handleUser = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setUser({ ...user, [name]: value });
  };
  const handleDate = (newValue) => {
    setDate(newValue);
  };
  const handleHeard = (value) => {
    setHeard(value);
    if (value.name && value.name === DICTIONARY.OTHER_HEARD_ABOUT) {
      setIsOtherAboutUs(true);
    }
  };
  const handleHeardOtherChange = (event) => {
    setHeardAboutUsOther(event.target.value);
  };
  const handlePayClick = () => {
    let number = user?.patient_phone_number;

    const requireFormFields = {
      patient_date_of_birth: date ? date.format('YYYY-MM-DD') : null,
      patient_clinical_gender: gender?.name || null,
      patient_phone_number: number,
      ...user,
    };
    const fields = {
      patient_status:
        patientStatus?.name === 'Current Patient' ? 'current' : 'new',
      heard_about_us: heard?.name,
      provider: data?.provider?.id,
      visit_reason: visitReasons?.id,
      insurance: insurancesValue?.id,
      state: stateName?.id,
      heard_about_us_other: heardAboutUsOther,
      time_slot: generateTime(data),
      insurance_carrier: insuranceCarrier?.id,
      insurance_plan: insurancePlan?.id,
      self_pay: selfPay,
    };
    if (requireValidate(requireFormFields)) {
      const payload = { ...requireFormFields, ...fields };
      setAppointmentFormData(payload);
      localStorage.setItem(DICTIONARY.APPOINTMENT, JSON.stringify(payload));
      bindClose();
    }
  };
  const handleGender = (value) => {
    setGender(value);
  };
  const renderSubmitButton = selfPay ? (
    <ButtonBase
      variant="contained"
      style={{ backgroundColor: '#31405C' }}
      onClick={handlePayClick}
    >
      Pay
    </ButtonBase>
  ) : (
    <ButtonBase
      variant="contained"
      style={{ backgroundColor: '#31405C' }}
      onClick={handleSubmit}
    >
      Submit
    </ButtonBase>
  );
  return (
    <>
      {loader && <ScreenLoader />}
      <Modal open={open} onClose={bindClose}>
        <StyledWrapper>
          <Heading>Create a new appointment</Heading>
          <SimpleBar
            className="modal-scroll"
            style={{
              maxHeight: 600,
              overflowX: 'hidden',
              padding: '0 15px',
            }}
          >
            <Flexbox m="50px 0px" gap="16px" flexDirection="column">
              <TextFieldBase
                label="Phone number *"
                value={user.patient_phone_number}
                onChange={handleUser}
                name="patient_phone_number"
                variant="filled"
                error={
                  errors?.patient_phone_number ||
                  formErrors?.patient_phone_number
                }
                helperText={
                  errors?.patient_phone_number
                    ? errors?.patient_phone_number[0]
                    : formErrors?.patient_phone_number
                }
              />
              <Flexbox alignItems="center" gap="16px">
                <TextFieldBase
                  label="First Name *"
                  value={user.patient_first_name}
                  onChange={handleUser}
                  name="patient_first_name"
                  variant="filled"
                  error={
                    errors?.patient_first_name || formErrors?.patient_first_name
                  }
                  helperText={
                    errors?.patient_first_name
                      ? errors?.patient_first_name[0]
                      : formErrors?.patient_first_name
                  }
                />
                <TextFieldBase
                  label="Last Name *"
                  value={user.patient_last_name}
                  onChange={handleUser}
                  name="patient_last_name"
                  variant="filled"
                  error={
                    errors?.patient_last_name || formErrors?.patient_last_name
                  }
                  helperText={
                    errors?.patient_last_name
                      ? errors?.patient_last_name[0]
                      : formErrors?.patient_last_name
                  }
                />
              </Flexbox>
              <TextFieldBase
                label="Email *"
                value={user.patient_email}
                onChange={handleUser}
                variant="filled"
                name="patient_email"
                error={errors?.patient_email || formErrors?.patient_email}
                helperText={
                  errors?.patient_email
                    ? errors?.patient_email[0]
                    : formErrors?.patient_email
                }
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledPicker
                  value={date}
                  onChange={handleDate}
                  label="Date of birth *"
                  slotProps={{
                    textField: {
                      variant: 'filled',
                      error:
                        errors?.patient_date_of_birth ||
                        formErrors?.patient_date_of_birth,
                      helperText: errors?.patient_date_of_birth
                        ? errors?.patient_date_of_birth[0]
                        : formErrors?.patient_date_of_birth,
                    },
                  }}
                />
              </LocalizationProvider>
              <BaseSelect
                label="Gender *"
                value={gender}
                onChange={handleGender}
                options={[
                  {
                    label: 'Male',
                    name: 'Male',
                  },
                  {
                    label: 'Female',
                    name: 'Female',
                  },
                ]}
                error={
                  errors?.patient_clinical_gender ||
                  formErrors?.patient_clinical_gender
                }
                helperText={
                  errors?.patient_clinical_gender
                    ? errors?.patient_clinical_gender[0]
                    : formErrors?.patient_clinical_gender
                }
              />
              <BaseSelect
                label="Heard about us"
                value={heard}
                onChange={handleHeard}
                options={HEARD_ABOUT_LIST}
                disableClearable
              />
              {isOtherAboutUs && (
                <TextFieldBase
                  label="How did you hear about us?"
                  value={heardAboutUsOther}
                  onChange={handleHeardOtherChange}
                  variant="filled"
                  name="heard_about_us_other"
                  error={errors?.heard_about_us_other}
                  helperText={
                    errors?.heard_about_us_other &&
                    errors?.heard_about_us_other[0]
                  }
                />
              )}
            </Flexbox>
          </SimpleBar>
          <Flexbox gap="16px" justifyContent="end">
            <ButtonBase
              variant="contained"
              style={{ backgroundColor: '#919191' }}
              onClick={bindClose}
            >
              Cancel
            </ButtonBase>
            {loader ? (
              <LoadingButtonStyled
                loading
                variant="contained"
                style={{ backgroundColor: '#31405C', color: '#fff' }}
                onClick={handleSubmit}
              ></LoadingButtonStyled>
            ) : (
              renderSubmitButton
            )}
          </Flexbox>
        </StyledWrapper>
      </Modal>
    </>
  );
};
export default AppointmentModule;
