import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import 'react-day-picker/dist/style.css';

import styles from './WeekView.module.css';

const WeekView = ({ range, onPrevDate, onNextDate, currentDates, count }) => {
  return (
    <div className={styles.view__wrapper}>
      <div className={styles.infoMedia}>{count || 0} providers found</div>
      <div className={styles.view}>
        <div className={styles.info}>{count || 0} providers found</div>
        <div className={styles.calendar}>
          <div>
            <ArrowBackIosNewOutlinedIcon
              onClick={onPrevDate}
              style={{
                fontSize: '16px',
                cursor: range.prev === 0 ? 'unset' : 'pointer',
                color: range.prev === 0 && '#ccc',
              }}
            />
          </div>
          {currentDates.map((item, index) => (
            <div className={styles.calendar__dayView} key={index}>
              <div className={styles.calendar__day}>{item.day}</div>
              <div className={styles.calendar__date}>{item.date}</div>
            </div>
          ))}
          <ArrowForwardIosOutlinedIcon
            style={{
              fontSize: '16px',
              cursor: range.next < 52 ? 'pointer' : 'unset',
              color: range.next < 52 ? '#31405C' : '#ccc',
            }}
            onClick={onNextDate}
          />
        </div>
      </div>
    </div>
  );
};

export default WeekView;
