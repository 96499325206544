import { Autocomplete, TextField, styled } from '@mui/material';
import React from 'react';

const TextFieldStyles = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    borderRadius: '20px',
  },
}));

const BaseMultiselect = ({ options, label, placeholder }) => {
  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => option?.name}
      defaultValue={[]}
      filterSelectedOptions
      renderInput={(params) => (
        <TextFieldStyles
          {...params}
          label={label}
          placeholder={placeholder || ''}
        />
      )}
    />
  );
};

export default BaseMultiselect;
