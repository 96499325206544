import { styled } from '@mui/material';

export const StyledWrapper = styled('div')(() => ({
  maxWidth: '800px',
  height: '600px',
  width: '100%',
  backgroundColor: '#fff',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px 10px',
  borderRadius: '10px',
  outline: 'none',
  overflowY: 'auto',

  '@media(max-width: 840px)': {
    maxWidth: '95%',
    h2: {
      fontSize: '20px',
    },
  },
}));
