import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material';

const SearchRoot = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'rgba(49, 64, 92, 1)',
  backgroundColor: '#fff',
  minWidth: 50,
  height: 50,
  borderRadius: '50%',

  '@media(max-width: 768px)': {
    borderRadius: 24,
    height: 40,
    marginTop: '12px',
  },
}));

const SearchButton = ({ onClick }) => {
  return (
    <SearchRoot onClick={onClick}>
      <SearchIcon />
    </SearchRoot>
  );
};

export default SearchButton;
