import React from 'react';

import BaseContainer from '../BaseContainer/BaseContainer';

import styles from './Header.module.css';

import logo from '../../assets/Logo.png';

const Header = ({ children }) => {
  return (
    <div className={styles.header}>
      <div className={styles.phone}>
        For help or in-person appointments call{' '}
        <a href="tel:(702) 848-2256">(702) 848-2256</a>
      </div>
      <div className={styles.header__logo}>
        <img src={logo} alt="logo" />
      </div>

      <BaseContainer>
        <div className={styles.header__text}>
          Please select your status, location, visit reason, and insurance or
          self pay to find available providers.
        </div>
        {children}
      </BaseContainer>
    </div>
  );
};

export default Header;
