import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';

import RootPagination from './ProviderPagination.styled';

const ProviderPagination = ({
  currentPage,
  totalCount,
  onPaginationChange,
  pageSize,
}) => {
  const totalPages = useMemo(() => {
    let total = +(totalCount / pageSize).toFixed(0);
    if (totalCount % pageSize > 0) {
      total += 1;
    }
    return total;
  }, [totalCount, pageSize]);

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      pt="20px"
      mb="20px"
    >
      <Stack spacing={2}>
        <RootPagination
          count={totalPages}
          page={currentPage}
          onChange={onPaginationChange}
        />
      </Stack>
    </Box>
  );
};

export default ProviderPagination;
