import apiRequest from './api';

export const getStates = () => {
  return apiRequest({
    url: '/states/',
    method: 'GET',
  });
};

export const getVisitTypes = () => {
  return apiRequest({
    url: '/visit-types/',
    method: 'GET',
  });
};

export const getClientAges = () => {
  return apiRequest({
    url: '/client-ages/',
    method: 'GET',
  });
};

export const getVisitReasons = () => {
  return apiRequest({
    url: '/visit-reasons/',
    method: 'GET',
  });
};

export const getInsurances = (parameters) => {
  return apiRequest({
    url: '/insurances/',
    method: 'GET',
    params: parameters,
  });
};

export const getProviders = (queries) => {
  return apiRequest({
    url: '/providers',
    method: 'GET',
    params: {
      ...queries,
    },
  });
};

export const createAppoinment = (payload) => {
  return apiRequest({
    url: '/appointments/',
    method: 'POST',
    data: { ...payload },
  });
};
