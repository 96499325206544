import React from 'react';

const PriceIcon = () => {
  return (
    <div
      style={{
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        role="img"
        aria-hidden="true"
        height="30"
        viewBox="0 0 17 20"
        fill="none"
      >
        <title>price icon</title>
        <path
          d="M8.333 1.667A8.336 8.336 0 0 0 0 10c0 4.6 3.733 8.333 8.333 8.333S16.667 14.6 16.667 10s-3.734-8.333-8.334-8.333ZM9.067 14.8v1.033H7.608v-1.075c-.616-.15-1.991-.641-2.516-2.466l1.375-.559c.05.184.483 1.742 2 1.742.775 0 1.65-.4 1.65-1.342 0-.8-.584-1.216-1.9-1.691-.917-.325-2.792-.859-2.792-2.759 0-.083.008-2 2.183-2.466v-1.05h1.459V5.2c1.533.267 2.091 1.492 2.216 1.858l-1.316.559A1.61 1.61 0 0 0 8.383 6.5c-.583 0-1.508.308-1.508 1.158 0 .792.717 1.092 2.2 1.584 2 .691 2.508 1.708 2.508 2.875 0 2.191-2.083 2.608-2.516 2.683Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  );
};

export default PriceIcon;
